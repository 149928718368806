<template>
  <div class="container">
    <el-row style="background-color: #ffffff">
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        :label="pane.label"
        :name="pane.ref"
        v-for="(pane, i) in paneList"
        :key="i"
      >
        <categoryList :ref="pane.ref" :source="pane.source"></categoryList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
  
  <script>
import categoryList from "./components/categoryList.vue";

export default {
  components: {
    categoryList,
  },
  data() {
    return {
      nav: { firstNav: "类目管理中心", secondNav: "类目管理" },
      activeName: 'sport',
      paneList: [
        {
          label: "体育课程",
          source: "课程",
          ref: "sport",
        },
         {
          label: "休闲生活",
          source: "休闲生活",
          ref: "life",
        },
         {
          label: "夜校",
          source: "夜校",
          ref: "night",
        },
         {
          label: "棋牌",
          source: "棋牌",
          ref: "chess",
        }
      ],
    };
  },
  mounted() {
      this.$nextTick(()=>{
        this.$refs[this.activeName][0].load();
      })
  },
  methods: {
    handleClick(v) {
      this.$refs[v.name][0].load();
    },
  },
};
</script>
  
  <style lang="less">
</style>
  